/* eslint-disable arrow-body-style */
import { createSlice } from '@reduxjs/toolkit';

const epicEmptyFunction = (state) => state;

const initialState = {
  user_received: undefined,
  initialData: undefined,
  isLoading: undefined,
  isUserLoading: undefined,
  oemsList: undefined
};

const settingProfileSlice = createSlice({
  name: 'settingProfile',
  initialState,
  reducers: {
    resendToEmail: epicEmptyFunction,
    getOEMs: epicEmptyFunction,
    getOEMsByID: epicEmptyFunction,
    updateIsEnableBilling: epicEmptyFunction,

    settingPrClearValues: (state) => {
      return {
        isLoading: state.isLoading
      };
    },
    settingPrGetUserSucc: {
      reducer(state, action) {
        return {
          initialData: action.payload.initialData,
          user_received: action.payload.user_received,
          isUserLoading: false
        };
      },
      prepare: (data) => ({ payload: { ...data, user_received: true } })
    },

    setSettingUserStatus: (state, { payload }) => {
      state.initialData.status = payload.status;
    },
    settingPrGetUser: (state) => {
      state.isLoading = true;
    },
    settingPrChangeModal: (state) => {
      state.isLoading = true;
    },
    settingPrHasChanged: (state) => {
      state.isLoading = false;
    },
    settingPrChange: (state) => {
      state.isLoading = true;
    },
    setOEMs: (state, action) => {
      state.oemsList = action.payload.oems;
    },
    resetSettingProfile: () => initialState
  }
});

export const {
  settingPrClearValues,
  settingPrGetUserSucc,
  settingPrChangeModal,
  settingPrHasChanged,
  settingPrGetUser,
  settingPrChange,
  resendToEmail,
  getOEMsByID,
  getOEMs,
  setOEMs,
  setSettingUserStatus,
  resetSettingProfile
} = settingProfileSlice.actions;

export default settingProfileSlice.reducer;
